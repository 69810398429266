import React, { MouseEventHandler } from "react";
import { useTranslation } from 'react-i18next';
import { Destination } from "../common/ObjectTypes";
import { Delete, SortingArrows } from "../common/ProjectIcons";
import Spinner from "../common/Spinner";
import NoResults from "../common/NoResults";

type ConnectionDestinationTableProps = {
    destinations: Destination[],
    existingDestPhase0: Destination[],
    existingDestPhase1: Destination[],
    existingDestPhase2: Destination[],
    existingDestPhase3: Destination[],
    addExistingDestinations: boolean,
    updatingDestinations: boolean,
    onAddExistingDestinations: MouseEventHandler,
    onClickAddDestination: Function,
    onViewDetails: Function,
    onRemoveDestination: Function,
    onSelectDestination: Function,
}

const ConnectionDestinationTable = ({
    destinations,
    existingDestPhase0,
    existingDestPhase1,
    existingDestPhase2,
    existingDestPhase3,
    addExistingDestinations,
    updatingDestinations,
    onAddExistingDestinations,
    onClickAddDestination,
    onViewDetails,
    onRemoveDestination,
    onSelectDestination
}: ConnectionDestinationTableProps) => {
    const { t } = useTranslation();
    let destlist = addExistingDestinations ? existingDestPhase0 : destinations;
    return (
        <div>
            <div className="mb-3">
                <label className="text-off-black">{addExistingDestinations ? "Select existing destinations to add to the connection" : `Connected destinations: ${destinations.length}`}</label>
            </div>
            <table className="table-auto h-auto w-full border border-gray-lines text-off-black bg-gray-lines rounded overflow-hidden">
                <thead className="bg-light-gray text-left text-sm font-bold uppercase border-b border-gray-lines">
                    <tr>
                        {addExistingDestinations && <th></th>}
                        <th className="p-3">Status</th>
                        <th className="p-3">Name</th>
                        <th className="p-3 flex items-center gap-2">
                            {t("Destination")}
                            <span><SortingArrows /></span>
                        </th>
                        <th className="p-3">Organization</th>
                        <th className="p-3">Type</th>
                        {!addExistingDestinations && <th></th>}
                    </tr>
                </thead>

                <tbody className="text-sm bg-white">
                    {addExistingDestinations && (
                        existingDestPhase3.map((destination: Destination, index: number) => {
                            let dest_url: string | URL;
                            try {
                                dest_url = new URL(destination.base_url).hostname;
                            } catch (err) {
                                dest_url = "";
                            }
                            const isLastRow = index === existingDestPhase3.length - 1;
                            return (
                                <tr key={destination.id} className={`border-b ${isLastRow ? 'border-b-4 border-gray-lines' : 'border-gray-lines'}`}>
                                    <td className="p-3">
                                        <input
                                            className="accent-dark-green cursor-pointer"
                                            type="checkbox"
                                            value={destination.id}
                                            id={destination.id}
                                            name={destination.name}
                                            checked={existingDestPhase2.map((a: any) => a.id).includes(destination.id)}
                                            onChange={() => onSelectDestination(destination)}
                                        />
                                    </td>
                                    <td className="p-3">------</td>
                                    <td className="p-3">{destination.name}</td>
                                    <td
                                        className="p-3 text-blue-basic cursor-pointer"
                                        onClick={() => onViewDetails(destination.id)}
                                    >
                                        {dest_url}
                                    </td>
                                    <td className="p-3">{destination.owner.name}</td>
                                    <td className="p-3">{destination.type.name}</td>
                                </tr>
                            );
                        })
                    )}
                    {updatingDestinations && (
                        <tr>
                            <td colSpan={6} className="p-3">
                                <Spinner />
                            </td>
                        </tr>)}
                    {destlist.length < 1 ? (
                        <tr>
                            <td colSpan={6} className="p-3 text-left text-secondary-gray">
                                {addExistingDestinations ?
                                    <NoResults
                                        headerMsg="No Results Found"
                                        labelMsg="You may need to adjust your search filters to find what you're searcing for"
                                    />
                                    : "No Destinations connected to this provider."}
                            </td>
                        </tr>
                    ) : (
                        !updatingDestinations && destlist.map((destination: Destination, index: number) => {
                            let dest_url: string | URL;
                            try {
                                dest_url = new URL(destination.base_url).hostname;
                            } catch (err) {
                                dest_url = "";
                            }
                            return (
                                <tr key={destination.id} className={"border-b border-gray-lines last:border-none"}>
                                    {addExistingDestinations && (
                                        <td className="p-3">
                                            <input
                                                className="accent-dark-green cursor-pointer"
                                                type="checkbox"
                                                value={destination.id}
                                                id={destination.id}
                                                name={destination.name}
                                                checked={existingDestPhase1.map((a: any) => a.id).includes(destination.id)}
                                                onChange={() => onSelectDestination(destination)}
                                            />
                                        </td>
                                    )}
                                    <td className="p-3">------</td>
                                    <td className="p-3">{destination.name}</td>
                                    <td
                                        className="p-3 text-blue-basic cursor-pointer"
                                        onClick={() => onViewDetails(destination.id)}
                                    >
                                        {dest_url}
                                    </td>
                                    <td className="p-3">{destination.owner.name}</td>
                                    <td className="p-3">{destination.type.name}</td>
                                    {!addExistingDestinations && (
                                        <td className="p-3 w-16">
                                            <div onClick={() => onRemoveDestination(destination)} className="bg-very-light-orange p-1 rounded-sm cursor-pointer">
                                                <Delete />
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            );
                        })

                    )}
                    {addExistingDestinations && (
                        <tr className="border-b border-gray-lines last:border-none">
                            <td colSpan={6} className="p-3 text-left">
                                <button onClick={onAddExistingDestinations} className={(existingDestPhase1.length > 0 || existingDestPhase2.length > 0) ? "bg-dark-green hover:bg-status-green text-white px-8 py-2 font-bold rounded mr-3" :
                                    "bg-light-green text-medium-gray px-3 py-2 font-bold rounded mr-3"}>Add Destinations</button>
                                <button onClick={() => onClickAddDestination(true)} className="bg-light-gray text-dark-gray font-semibold rounded py-2 px-3 text-sm mr-2" >Cancel</button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            {!addExistingDestinations && <div className="flex flex-row mt-3">
                <button onClick={() => onClickAddDestination(false)} className="bg-dark-green text-white font-semibold rounded py-2 px-3 text-sm mr-2">+ New Destination</button>
                <button onClick={() => onClickAddDestination(true)} className="bg-light-green text-dark-green font-semibold rounded py-2 px-3 text-sm">+ Existing Destination</button>
            </div>}
        </div>
    );
};

export default ConnectionDestinationTable;