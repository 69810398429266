type LogFilterTypes = {
    log_level: FilterTags[],
    log_type: FilterTags[],
    from_date: FilterTags[],
    to_date: FilterTags[],
    search: string
}
type FilterTags = {
    name: string,
    id: string
};

export const logGlobalSearch = (searchSubString: string, logsFilter: any) => {
    logsFilter["search"] = searchSubString;
    return logsFilter;
}

export const filterLogType = (e: any, logsFilter: any) => {
    let tempArray: FilterTags[] = logsFilter.log_type;
    let type = e.target.value;
    let index = e.target.id as keyof LogFilterTypes;
    let label = "";
    switch (type) {
        case "cdc":
            label = "Data Changes";
            break;
        case "ev":
            label = "Events";
            break;
        default:
            label = "--";
            break;
    }

    if (tempArray.map(a => a.id).includes(type)) {
        let removed: FilterTags[] = []
        for (var item in tempArray) {
            if (tempArray[item].id !== type) {
                removed.push(tempArray[item])
            }
        }
        logsFilter[index] = tempArray;
    } else {
        let newItem = {
            name: label,
            id: type,
            attribute_name: "type"
        }
        tempArray = [newItem];
        logsFilter[index] = tempArray
    }

    return logsFilter;
}

export const filterLogStatus = (e: any, logsFilter: any) => {
    let tempArray: FilterTags[] = logsFilter.log_level;
    let level = e.target.value;
    let index = e.target.id as keyof LogFilterTypes;
    let label = ""
    switch (level) {
        case "10":
            label = "All logs";
            break;
        case "20":
            label = "Basic";
            break;
        case "30":
            label = "Warning";
            break;
        case "40":
            label = "Error";
            break;
        default:
            label = "--";
            break;
    }

    if (tempArray.map(a => a.id).includes(level)) {
        let removed: FilterTags[] = []
        for (var item in tempArray) {
            if (tempArray[item].id !== level) {
                removed.push(tempArray[item])
            }
            logsFilter[index] = removed
        }
    } else {
        let newItem = {
            name: label,
            id: level,
            attribute_name: "status"
        }
        tempArray = [newItem];
        logsFilter[index] = tempArray
    }
    return logsFilter;
}

export const filterLogDate = (e: Date, side: string, logsFilter: any) => {
    let newItem = {
        name: side === "to_date" ? "End Date & Time" : "Start Date & Time",
        id: e.toISOString(),
        attribute_name: "datetime"
    }
    logsFilter[side] = [newItem];
    return logsFilter;
}

export const removeLogFilterTag = (e: any, logsFilter: any) => {
    if (logsFilter.log_level.map((a: any) => a.id).includes(e)) {
        let tempArray: FilterTags[] = logsFilter.log_level
        let removed: FilterTags[] = []
        for (var item in tempArray) {
            if (tempArray[item].id !== e) removed.push(tempArray[item])
        }
        logsFilter.log_level = removed
    } else if (logsFilter.log_type.map((a: any) => a.id).includes(e)) {
        let tempArray: FilterTags[] = logsFilter.log_type
        let removed: FilterTags[] = []
        for (item in tempArray) {
            if (tempArray[item].id !== e) removed.push(tempArray[item])
        }
        logsFilter.log_type = removed
    } else if (logsFilter.from_date.map((a: any) => a.id).includes(e)) {
        let tempArray: FilterTags[] = logsFilter.from_date
        let removed: FilterTags[] = []
        for (item in tempArray) {
            if (tempArray[item].id !== e) removed.push(tempArray[item])
        }
        logsFilter.from_date = removed
    } else {
        let tempArray: FilterTags[] = logsFilter.to_date
        let removed: FilterTags[] = []
        for (item in tempArray) {
            if (tempArray[item].id !== e) removed.push(tempArray[item])
        }
        logsFilter.to_date = removed
    }
    return logsFilter;
}

export const resetLogFilters = () => {
    return {
        log_level: [],
        log_type: [],
        from_date: [],
        to_date: [],
        search: "",
        ordering: ""
    }
}

export const getUtcOffsets = () => {
    let utcOffsets = [];
    const halfHourOffsets = [-9, -3, 3, 4, 5, 6, 9, 10]; // Values that require an additional ":30" offset
    const quarterHourOffsets = [5, 8, 12]; // Values that require an additional ":45" offset

    for (let i = -12; i <= 14; i++) {
        // Determine the base offset and label
        let baseOffset = i >= 0 && i < 10 ? "+0" + i : i >= 10 ? "+" + i : (i > -10 && i < 0) ? "-0" + Math.abs(i) : i.toString();
        let baseLabel = i === 0 ? "UTC" : i > 0 && i < 10 ? "UTC+0" + i : i >= 10 ? "UTC+" + i : (i > -10 && i < 0) ? "UTC-0" + Math.abs(i) : "UTC" + i;

        // Add the base offset
        utcOffsets.push({ value: baseOffset + "00", label: baseLabel });

        // Check if the current value requires an additional ":30" offset
        if (halfHourOffsets.includes(i)) {
            let offset30 = baseOffset + "30"; // Adjust the offset for ":30"
            let label30 = baseLabel + ":30"; // Adjust the label for ":30"
            utcOffsets.push({ value: offset30, label: label30 });
        }

        // Check if the current value requires an additional ":45" offset
        if (quarterHourOffsets.includes(i)) {
            let offset45 = baseOffset + "45"; // Adjust the offset for ":45"
            let label45 = baseLabel + ":45"; // Adjust the label for ":45"
            utcOffsets.push({ value: offset45, label: label45 });
        }
    }
    return utcOffsets;
}

export const parseCursor = (url: string): string | null => {
    if (url) {
        const urlObj = new URL(url);
        return urlObj.searchParams.get("cursor");
    } else {
        return null
    }
};