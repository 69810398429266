import { getOrganizationsList } from "../organizations/organizationsApi";

const getUserPermissions = async (
    keycloak_token: string,
): Promise<boolean> => {
    let can_add_destination = false;
    try {
        const temp = await getOrganizationsList(keycloak_token, {});
        for (let org of temp.results) {
            if (org.role === "admin" || org.role === "superuser") {
                can_add_destination = true
                break;
            }
        }
        return can_add_destination;
    } catch (err: any) {
        console.log(err);
        return false;
    }
}

export default getUserPermissions;