import React, { MouseEventHandler } from 'react';
import ReactPaginate from 'react-paginate';
import { BackArrow, NextArrow, PrevArrow } from './ProjectIcons';

type PaginationProps = {
    showText: boolean,
    handlePageClick?: Function,
    handleNextPageClick?: MouseEventHandler,
    handlePrevPageClick?: MouseEventHandler,
    pageCount?: number,
    resultStart?: number,
    resultEnd?: number,
    prevCursor?: string,
    nextCursor?: string,
    totalCount?: number,
    take: number,
    loading: boolean,
    mainclassname: string,
    initialPage?: number;
    pageRangeDisplayed?: number;
    marginPagesDisplayed?: number;
}

const Pagination = ({
    showText,
    handlePageClick,
    handlePrevPageClick,
    handleNextPageClick,
    pageCount,
    resultStart,
    resultEnd,
    prevCursor,
    nextCursor,
    totalCount,
    take,
    loading,
    mainclassname,
    initialPage,
    pageRangeDisplayed,
    marginPagesDisplayed
}: PaginationProps) => {
    return (
        <div className={mainclassname}>
            {showText ?
                <div>
                    {totalCount && (totalCount > 0) && (
                        loading ? (
                            <span className='w-full text-secondary-gray py-2'>
                                Loading...
                            </span>
                        ) : (
                            <span className='w-full text-secondary-gray py-2'>
                                Showing {resultStart} to {resultEnd} of {totalCount}
                            </span>
                        )
                    )}
                </div> :
                <div>
                    {/* {totalCount > 0 && <span className='w-full text-secondary-gray py-2'>Load more</span>} */}
                </div>
            }
            <ReactPaginate
                previousLabel={((prevCursor) || (totalCount && totalCount > take)) ? <div onClick={handlePrevPageClick}><PrevArrow /></div> : null}
                nextLabel={((nextCursor) || (totalCount && totalCount > take)) ? <div onClick={handleNextPageClick}><NextArrow /></div> : null}
                breakLabel={". . ."}
                pageCount={pageCount && pageCount ? pageCount : 100}
                onPageChange={(e) => handlePageClick && handlePageClick(e)}
                containerClassName={"pagination flex flex-row text-secondary-gray justify-end my-0 pb-1 mx-8"}
                pageClassName={showText ? "mx-4" : "hidden"}
                activeClassName={"active border border-secondary-gray rounded-full px-2"}
                forcePage={initialPage}
                pageRangeDisplayed={pageRangeDisplayed}
                marginPagesDisplayed={marginPagesDisplayed}
            />
        </div>
    )
}

export default Pagination;