import React from 'react';
import { withBaseIcon } from 'react-icons-kit';
import { iosMoon } from 'react-icons-kit/ionicons/iosMoon';
import { adjustBrightness } from 'react-icons-kit/typicons/adjustBrightness';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
import { arrows_slim_left } from 'react-icons-kit/linea/arrows_slim_left';
import { arrows_slim_right } from 'react-icons-kit/linea/arrows_slim_right';
import { ic_filter_list } from 'react-icons-kit/md/ic_filter_list';
import { ic_clear } from 'react-icons-kit/md/ic_clear';
import { arrowUnsorted } from 'react-icons-kit/typicons/arrowUnsorted';
import { arrowSortedUp } from 'react-icons-kit/typicons/arrowSortedUp';
import { arrowSortedDown } from 'react-icons-kit/typicons/arrowSortedDown';
import { ic_error } from 'react-icons-kit/md/ic_error';
import { ic_fiber_manual_record } from 'react-icons-kit/md/ic_fiber_manual_record';
import { basic_magnifier } from 'react-icons-kit/linea/basic_magnifier';
import { ic_keyboard_arrow_down } from 'react-icons-kit/md/ic_keyboard_arrow_down';
import { ic_keyboard_arrow_right } from 'react-icons-kit/md/ic_keyboard_arrow_right'
import { ic_keyboard_arrow_up } from 'react-icons-kit/md/ic_keyboard_arrow_up';
import { lineArrowLeft } from 'react-icons-kit/oct/lineArrowLeft';
import { lineArrowRight } from 'react-icons-kit/oct/lineArrowRight';
import { eye } from 'react-icons-kit/entypo/eye';
import { withLine } from 'react-icons-kit/entypo/withLine';
import { iosInformationOutline } from 'react-icons-kit/ionicons/iosInformationOutline';

const IconContainer = withBaseIcon({ size: 30 });
interface ArrowProps {
    onClick?: Function;
}

export const IosMoon = () => <IconContainer icon={iosMoon} />;
export const IosSunny = () => <IconContainer icon={adjustBrightness} />;
export const Delete = () => <IconContainer icon={ic_delete} className="text-orange" />;
export const BackArrow = () => <IconContainer icon={arrows_slim_left} size={18} />;
export const NextArrow: React.FC<ArrowProps> = ({ onClick }) => (
    <IconContainer icon={lineArrowRight} size={18} onClick={onClick} />
);
export const PrevArrow: React.FC<ArrowProps> = ({ onClick }) => (
    <IconContainer icon={lineArrowLeft} size={18} onClick={onClick} />
);
export const FilterIcon = () => <IconContainer icon={ic_filter_list} size={20} />
export const Clear = () => <IconContainer icon={ic_clear} size={18} />
export const Clear_Large = () => <IconContainer icon={ic_clear} size={24} />
export const SortingArrows = () => <IconContainer icon={arrowUnsorted} size={18} className="text-dark-green" />
export const SortedUp = () => <IconContainer icon={arrowSortedUp} size={18} className="text-bright-green" />
export const SortedDown = () => <IconContainer icon={arrowSortedDown} size={18} className="text-bright-green" />
export const ProfileDropdown = () => <IconContainer icon={arrowSortedDown} size={18} className="text-gray-lines" />
export const AttentionIcon = () => <IconContainer icon={ic_error} size={18} className="text-orange" />
export const HealthyIcon = () => <IconContainer icon={ic_fiber_manual_record} size={22} className="text-status-green" />
export const SearchIcon = () => <IconContainer icon={basic_magnifier} size={18} />;
export const ArrowUp = () => <IconContainer icon={ic_keyboard_arrow_up} size={24} className="text-dark-green" />
export const ArrowDown = () => <IconContainer icon={ic_keyboard_arrow_down} size={24} className="text-dark-green" />
export const ArrowRight = () => <IconContainer icon={ic_keyboard_arrow_right} size={24} className="text-dark-green" />
export const ShowString = () => <IconContainer icon={eye} size={18} />
export const HideString = () => <IconContainer icon={withLine} size={18} />
export const ToolTipIcon = () => <IconContainer icon={iosInformationOutline} size={18} />