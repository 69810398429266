import React, { ChangeEventHandler, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Destination, DestinationType, Organization } from "../common/ObjectTypes";
import Pagination from "../common/Pagination";
import { SortedDown, SortedUp, SortingArrows } from "../common/ProjectIcons";
import DestinationFilter from "./DestinationFilter";
import NoResults from "../common/NoResults";
import { page_size } from "../../api/apiUtils";

type DestinationsListProps = {
    canAddDestination: boolean,
    reloadDestinations: boolean,
    destinations: Destination[],
    destinationTypes: DestinationType[],
    destinationUrls: string[],
    loadingDestinationUrls: boolean,
    loadMoreDestinationUrls: boolean,
    onLoadMoreDestinationUrls: Function,
    organizations: Organization[],
    onAddDestination: Function,
    onViewDetails: Function,
    onChangeEndpoint: ChangeEventHandler,
    onChangeState: ChangeEventHandler,
    onChangeType: ChangeEventHandler,
    onChangeDestinationTypeSearchBar: ChangeEventHandler,
    loadingDestinationTypes: boolean,
    loadMoreDestinationTypes: boolean,
    onLoadMoreDestinationTypes: Function,
    onChangeOwner: ChangeEventHandler,
    onLoadMoreOrganizations: Function,
    onChangeOwnerSearchBar: ChangeEventHandler,
    loadMoreOrganizations: boolean,
    loadingOrganizations: boolean,
    onChangeDestinationUrlSearchBar: ChangeEventHandler,
    onChangeGlobalSearch: Function,
    onRemoveTag: any,
    onResetFilters: any,
    filters: any[],
    globalSearchStr: string,
    requestSort: Function,
    onPageClicked: Function,
    totalCount: number,
    pageLoaded: boolean,
    take: number,
    offset: number
}

const DestinationsList = ({
    canAddDestination,
    reloadDestinations,
    destinations,
    destinationTypes,
    destinationUrls,
    loadingDestinationUrls,
    loadMoreDestinationUrls,
    onLoadMoreDestinationUrls,
    organizations,
    onAddDestination,
    onViewDetails,
    onChangeEndpoint,
    onChangeState,
    onChangeType,
    onChangeDestinationTypeSearchBar,
    loadingDestinationTypes,
    loadMoreDestinationTypes,
    onLoadMoreDestinationTypes,
    onChangeOwner,
    onChangeOwnerSearchBar,
    loadMoreOrganizations,
    loadingOrganizations,
    onLoadMoreOrganizations,
    onChangeDestinationUrlSearchBar,
    onChangeGlobalSearch,
    onRemoveTag,
    onResetFilters,
    filters,
    globalSearchStr,
    requestSort,
    onPageClicked,
    totalCount,
    pageLoaded,
    take,
    offset
}: DestinationsListProps) => {
    const { t } = useTranslation();
    const [sortDirection, setSortDirection] = useState(false);
    const [sortByColumn, setSortByColumn] = useState("name");

    return (
        <div className="bg-very-light-gray">
            <div className="mb-3 flex justify-between">
                <div>
                    <h1 data-testid="destinations_page_header" className="text-3xl font-bold mb-3">
                        {t("Destinations")}
                    </h1>
                </div>
                {canAddDestination && <button className="bg-dark-green hover:bg-status-green text-white font-bold rounded px-7"
                    onClick={() => onAddDestination(null)}>
                    Create Destination
                </button>}
            </div>
            <div className="flex flex-row items-start w-full">
                {/* FILTERS */}
                <DestinationFilter
                    destinationUrls={destinationUrls}
                    destinationTypes={destinationTypes}
                    organizations={organizations}
                    filters={filters}
                    globalSearchStr={globalSearchStr}
                    onChangeGlobalSearch={onChangeGlobalSearch}
                    onRemoveTag={onRemoveTag}
                    onResetFilters={onResetFilters}
                    onChangeDestinationUrlSearchBar={onChangeDestinationUrlSearchBar}
                    onChangeEndpoint={onChangeEndpoint}
                    onChangeState={onChangeState}
                    onChangeType={onChangeType}
                    onChangeDestinationTypeSearchBar={onChangeDestinationTypeSearchBar}
                    loadingDestinationTypes={loadingDestinationTypes}
                    loadMoreDestinationTypes={loadMoreDestinationTypes}
                    onLoadMoreDestinationTypes={onLoadMoreDestinationTypes}
                    onChangeOwnerSearchBar={onChangeOwnerSearchBar}
                    onLoadMoreOrganizations={onLoadMoreOrganizations}
                    loadingOrganizations={loadingOrganizations}
                    loadMoreOrganizations={loadMoreOrganizations}
                    onChangeOwner={onChangeOwner}
                    showStatus={true}
                    showUrlFilter={true}
                    loadingDestinationUrls={loadingDestinationUrls}
                    loadMoreDestinationUrls={loadMoreDestinationUrls}
                    onLoadMoreDestinationUrls={onLoadMoreDestinationUrls}
                />
                <div className="flex flex-col w-full">


                    {destinations.length < 1 &&
                        <NoResults
                            headerMsg="No Results Found"
                            labelMsg="You may need to adjust your search or filters to find what you are looking for."
                        />}

                    {destinations.length >= 1 &&
                        <table className="table-auto h-auto w-full cursor-pointer border border-gray-lines text-off-black rounded-md bg-very-light-gray">
                            <thead className="px-3 pt-3 pb-2 font-bold uppercase text-sm bg-light-gray text-left">
                                <tr>
                                    <th className="p-3">
                                        {t("Destination")}
                                        <span onClick={() => {
                                            requestSort("name", sortDirection)
                                            setSortDirection(!sortDirection)
                                            setSortByColumn("name")
                                        }}>
                                            {sortByColumn === "name" ?
                                                sortDirection ? <SortedUp /> : <SortedDown />
                                                : <SortingArrows />}
                                        </span>
                                    </th>
                                    <th className="p-3">
                                        URL
                                        <span onClick={() => {
                                            requestSort("endpoint", sortDirection)
                                            setSortDirection(!sortDirection)
                                            setSortByColumn("endpoint")
                                        }}>
                                            {sortByColumn === "endpoint" ?
                                                sortDirection ? <SortedUp /> : <SortedDown />
                                                : <SortingArrows />}
                                        </span>
                                    </th>
                                    <th className="p-3">
                                        {t("Type")}
                                        <span onClick={() => {
                                            requestSort("type__name", sortDirection)
                                            setSortDirection(!sortDirection)
                                            setSortByColumn("type__name")
                                        }}>
                                            {sortByColumn === "type__name" ?
                                                sortDirection ? <SortedUp /> : <SortedDown />
                                                : <SortingArrows />}
                                        </span>
                                    </th>
                                    <th className="p-3">
                                        {t("Organization")}
                                        <span onClick={() => {
                                            requestSort("owner__name", sortDirection)
                                            setSortDirection(!sortDirection)
                                            setSortByColumn("owner__name")
                                        }}>
                                            {sortByColumn === "owner__name" ?
                                                sortDirection ? <SortedUp /> : <SortedDown />
                                                : <SortingArrows />}
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            {reloadDestinations ?
                                <tbody className="p-6">
                                    <tr>
                                        <td className="p-6">...</td>
                                        <td className="p-6">...</td>
                                        <td className="p-6">...</td>
                                        <td className="p-6">...</td>
                                    </tr>
                                </tbody>
                                :
                                <tbody className="text-sm">
                                    {destinations.map((destination: Destination) => {
                                        let dest_url: string | URL;
                                        try {
                                            dest_url = new URL(destination.base_url).hostname
                                        } catch (err) {
                                            dest_url = ""
                                        }
                                        return (
                                            <tr key={destination.id}
                                                className="border border-gray-lines hover:bg-light-blue bg-white"
                                                onClick={() => onViewDetails(destination.id)}
                                            >
                                                <td className="p-3">
                                                    {destination.name}
                                                </td>
                                                <td className="p-3 text-blue-basic">
                                                    {dest_url}
                                                </td>
                                                <td className="p-3">
                                                    {destination.type.name}
                                                </td>
                                                <td className="p-3">
                                                    {destination.owner.name}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>}
                        </table>}
                    {totalCount > page_size && <Pagination
                        showText={true}
                        handlePageClick={onPageClicked}
                        pageCount={Math.ceil(totalCount / take)}
                        resultStart={offset + 1}
                        resultEnd={(offset + take) < totalCount ? (offset + take) : totalCount}
                        totalCount={totalCount}
                        take={take}
                        loading={!pageLoaded}
                        mainclassname="w-full mt-2 flex flex-row justify-between bg-very-light-gray opacity-80"
                    />}
                </div>
            </div>
        </div>
    )
};

export default DestinationsList;