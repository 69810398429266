import React, { MouseEventHandler } from "react";
import { Connection } from "../common/ObjectTypes";

type ConnectionDetailsProps = {
    connection: Connection,
    subPage: string | undefined,
    onSelectSubPage: Function,
    onUpdateConnection: MouseEventHandler,
    onCancelConnectionChanges: MouseEventHandler,
    changesMade: boolean,
}

const ConnectionDetails = ({
    connection,
    subPage,
    onSelectSubPage,
    onUpdateConnection,
    onCancelConnectionChanges,
    changesMade,
}: ConnectionDetailsProps) => {

    return (
        <div className="relative h-full">
            <div className="bg-white shadow static inset-x-0 px-12 pt-5  -mt-5 -mx-5 xl:-mx-12">
                {/* page header */}
                <div className="flex flex-row justify-between">
                    <h1 className="text-3xl font-semibold mb-3">{connection.provider.name}</h1>
                    <div className="flex flex-col">
                        <label className="text-secondary-gray">Organization: {connection.owner.name}</label>
                        <label className="text-secondary-gray">Provider Type: {connection.provider.type.name}</label>
                        {/* <label className="text-secondary-gray">Last Delivery: [Timestamp]</label> */}
                    </div>
                </div>
                {/* tabs for sub-pages */}
                <div className="w-full flex flex-row font-semibold text-medium-gray">
                    <label className={(subPage === "overview") ? "border-2 border-white border-b-status-green text-dark-gray mr-3 py-3 cursor-pointer" : "border-2 border-white mr-3 py-3 cursor-pointer"}
                        onClick={() => {
                            onSelectSubPage("overview")
                        }}>
                        Overview
                    </label>
                    <label className={(subPage === "provider") ? "border-2 border-white border-b-status-green text-dark-gray mx-3 py-3 cursor-pointer" : "border-2 border-white mx-3 py-3 cursor-pointer"}
                        onClick={() => {
                            onSelectSubPage("provider")
                        }}>
                        Provider
                    </label>
                    <label className={(subPage === "destinations") ? "border-2 border-white border-b-status-green text-dark-gray py-3 mx-3 cursor-pointer" : "border-2 border-white mx-3 py-3 cursor-pointer"}
                        onClick={() => {
                            onSelectSubPage("destinations")
                        }}>
                        Destinations
                    </label>
                    <label className={(subPage === "sources") ? "border-2 border-white border-b-status-green text-dark-gray mx-3 py-3 cursor-pointer" : "border-2 border-white mx-3 py-3 cursor-pointer"}
                        onClick={() => {
                            onSelectSubPage("sources")
                        }}>
                        Sources
                    </label>
                    <label className={(subPage === "logs") ? "border-2 border-white border-b-status-green text-dark-gray mx-3 py-3 cursor-pointer" : "border-2 border-white mx-3 py-3 cursor-pointer"}
                        onClick={() => {
                            onSelectSubPage("logs")
                        }}>
                        Logs
                    </label>

                </div>
            </div>
            {/* buttons */}
            <div className="w-full z-10 fixed bottom-0 mt-12 mr-6 py-3 px-12 w-full flex flex-row-reverse bg-very-light-gray">
                <button className={changesMade ? "bg-dark-green hover:bg-status-green text-white px-8 py-2 font-bold rounded mr-3" :
                    "bg-light-green text-medium-gray px-8 py-2 font-bold rounded mr-3"}
                    disabled={!changesMade}
                    onClick={onUpdateConnection}>
                    Save
                </button>
                <button className={"text-secondary-gray font-bold px-12 mx-3"}
                    onClick={onCancelConnectionChanges}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default ConnectionDetails;