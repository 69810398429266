import React, { ChangeEventHandler, MouseEventHandler } from "react";
import { useTranslation } from 'react-i18next';
import { Delete, SortingArrows } from "../common/ProjectIcons";
import { Destination, DestinationType, Organization } from "../common/ObjectTypes";
import DestinationFilter from "../destinations/DestinationFilter";
import Pagination from "../common/Pagination";
import NoResults from "../common/NoResults";
import { page_size } from "../../api/apiUtils";
import Spinner from "../common/Spinner";
import ConnectionDestinationTable from "../destinations/ConnectionDestinationTable";

type ConnectionDetailsDestinationProps = {
    destinations: Destination[],
    existingDestPhase0: Destination[],
    existingDestPhase1: Destination[],
    existingDestPhase2: Destination[],
    existingDestPhase3: Destination[],
    onSelectDestination: Function,
    onRemoveDestination: Function,
    onViewDetails: Function,
    organizations: Organization[],
    destinationUrls: string[],
    destinationTypes: DestinationType[],
    onClickAddDestination: Function,
    onAddExistingDestinations: MouseEventHandler,
    updatingDestinations: boolean,
    addExistingDest: boolean,
    onChangeDestinationUrlSearchBar: ChangeEventHandler,
    loadMoreDestinationUrls: boolean,
    loadingDestinationUrls: boolean,
    onLoadMoreDestinationUrls: Function,
    onLoadMoreOrganizations: Function,
    loadingOrganizations: boolean,
    loadMoreOrganizations: boolean,
    onChangeOwnerSearchBar: ChangeEventHandler,
    onChangeDestinationOwnerFilter: ChangeEventHandler,
    onChangeDestinationUrlFilter: ChangeEventHandler,
    onChangeDestinationTypeFilter: ChangeEventHandler,
    onChangeDestinationTypeSearchBar: ChangeEventHandler,
    onLoadMoreDestinationTypes: Function,
    loadingDestinationTypes: boolean,
    loadMoreDestinationTypes: boolean,
    onChangeGlobalDestinationSearch: Function,
    filters: any,
    onRemoveDestinationFilterTag: Function,
    onResetDestinationFilters: Function
}

const ConnectionDetailsDestination = ({
    destinations,
    existingDestPhase0,
    existingDestPhase1,
    existingDestPhase2,
    existingDestPhase3,
    onSelectDestination,
    onRemoveDestination,
    onViewDetails,
    organizations,
    destinationUrls,
    destinationTypes,
    onClickAddDestination,
    onAddExistingDestinations,
    updatingDestinations,
    addExistingDest,
    onChangeDestinationUrlSearchBar,
    loadingDestinationUrls,
    loadMoreDestinationUrls,
    onLoadMoreDestinationUrls,
    onLoadMoreOrganizations,
    loadingOrganizations,
    loadMoreOrganizations,
    onChangeOwnerSearchBar,
    onChangeDestinationOwnerFilter,
    onChangeDestinationUrlFilter,
    onChangeDestinationTypeFilter,
    onChangeDestinationTypeSearchBar,
    onLoadMoreDestinationTypes,
    loadMoreDestinationTypes,
    loadingDestinationTypes,
    onChangeGlobalDestinationSearch,
    filters,
    onRemoveDestinationFilterTag,
    onResetDestinationFilters
}: ConnectionDetailsDestinationProps) => {
    const { t } = useTranslation();
    return (
        <div className="mt-8">
            {/* div with text and add button */}
            <div className="flex flex-row justify-between items-center w-full">
                <label className="text-secondary-gray">Data from the provider are sent to the following destinations.</label>
            </div>
            {/* div with search bar and table*/}
            <div className="flex flex-row mt-8 mb-12 mr-2 overflow-x-hidden">
                {addExistingDest && <DestinationFilter
                    destinationUrls={destinationUrls}
                    destinationTypes={destinationTypes}
                    organizations={organizations}
                    filters={filters}
                    onChangeGlobalSearch={onChangeGlobalDestinationSearch}
                    onRemoveTag={onRemoveDestinationFilterTag}
                    onResetFilters={onResetDestinationFilters}
                    onChangeDestinationUrlSearchBar={onChangeDestinationUrlSearchBar}
                    onChangeEndpoint={onChangeDestinationUrlFilter}
                    onChangeType={onChangeDestinationTypeFilter}
                    onChangeOwner={onChangeDestinationOwnerFilter}
                    showStatus={false}
                    showUrlFilter={true}
                    loadingDestinationUrls={loadingDestinationUrls}
                    loadMoreDestinationUrls={loadMoreDestinationUrls}
                    onLoadMoreDestinationUrls={onLoadMoreDestinationUrls}
                    onLoadMoreOrganizations={onLoadMoreOrganizations}
                    onChangeOwnerSearchBar={onChangeOwnerSearchBar}
                    loadingOrganizations={loadingOrganizations}
                    loadMoreOrganizations={loadMoreOrganizations}
                    onChangeDestinationTypeSearchBar={onChangeDestinationTypeSearchBar}
                    loadMoreDestinationTypes={loadMoreDestinationTypes}
                    loadingDestinationTypes={loadingDestinationTypes}
                    onLoadMoreDestinationTypes={onLoadMoreDestinationTypes}
                />}
                <div className={addExistingDest ? "w-5/6" : "w-full"}>
                    <div className="flex flex-col w-full">
                        <ConnectionDestinationTable
                            destinations={destinations}
                            existingDestPhase0={existingDestPhase0}
                            existingDestPhase1={existingDestPhase1}
                            existingDestPhase2={existingDestPhase2}
                            existingDestPhase3={existingDestPhase3}
                            addExistingDestinations={addExistingDest}
                            onAddExistingDestinations={onAddExistingDestinations}
                            updatingDestinations={updatingDestinations}
                            onClickAddDestination={onClickAddDestination}
                            onViewDetails={onViewDetails}
                            onRemoveDestination={onRemoveDestination}
                            onSelectDestination={onSelectDestination}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ConnectionDetailsDestination;