import React, { useEffect, useState } from "react";
import { useKeycloak } from '@react-keycloak/web';
import Spinner from "../common/Spinner";
import OrganizationsList from "./OrganizationsList";
import { useNavigate } from 'react-router-dom';
import { Organization } from "../common/ObjectTypes";
import { ManageOrganizationModal } from "./ManageOrganizationModal";
import { getMyDetails } from "../../api/users/usersApi";
import loadOrganizations from "../../api/organizations/loadOrganizations";
import { page_size } from "../../api/apiUtils";

const OrganizationPage = ({ }) => {
    const { keycloak } = useKeycloak();
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [searchString, setSearchString] = useState<string>("");
    const [myDetails, setMyDetails] = useState<any>({});
    const [pageLoaded, setPageLoaded] = useState(false);
    const [reloadOrgs, setReloadOrgs] = useState(true);
    const [addOrgModal, setAddOrgModal] = useState(false);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [offset, setOffset] = useState<number>(0);
    const navigate = useNavigate()

    useEffect(() => {
        const loadOrganizationsData = async () => {
            if (keycloak.token) {
                const [orgs, total_orgs, error] = await loadOrganizations(keycloak.token, offset, searchString, organizations, true);
                if (!error) {
                    setOrganizations(orgs);
                    setReloadOrgs(false);
                    setTotalCount(total_orgs);
                    setPageLoaded(true);
                }
            }
        }
        const loadMyDetails = async () => {
            if (keycloak.token) {
                const user = await getMyDetails(keycloak.token);
                setMyDetails(user);
            }
        }
        if (reloadOrgs) loadOrganizationsData();
        if (!pageLoaded) loadMyDetails();
    }, [keycloak.authenticated, pageLoaded, reloadOrgs])

    const handleChangeSearchString = (e: any) => {
        setSearchString(e.target.value);
    }

    const handleSearch = () => {
        setOffset(0);
        setReloadOrgs(true);
    }

    const handleSelectOrg = (org_id: string) => {
        navigate("/organizations/" + org_id)
    }

    const handleToggleOrgModal: React.MouseEventHandler<HTMLButtonElement> = () => {
        setAddOrgModal(!addOrgModal);
        setReloadOrgs(true);
    }

    const handlePageClick = (e: any) => {
        setOffset(e.selected * page_size);
        setReloadOrgs(true);
    }

    return !pageLoaded ?
        (
            <Spinner />
        ) : (
            <div>
                {addOrgModal &&
                    <ManageOrganizationModal
                        onToggleModal={handleToggleOrgModal}
                        newOrg={true}
                    />
                }
                <OrganizationsList
                    data-testid="organizations_list"
                    organizations={organizations}
                    onChangeSearchString={handleChangeSearchString}
                    onSelectOrg={handleSelectOrg}
                    onAddOrg={handleToggleOrgModal}
                    superuser={myDetails.is_superuser}
                    onPageClicked={handlePageClick}
                    totalCount={totalCount}
                    pageLoaded={pageLoaded}
                    take={page_size}
                    offset={offset}
                    searchString={searchString}
                    onSearch={handleSearch}
                />
            </div>
        );
};

export default OrganizationPage;