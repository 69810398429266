import React, { useState } from 'react';
import { ToolTipIcon } from './ProjectIcons';

interface TooltipProps {
    description: string;
    position?: 'top' | 'bottom' | 'left' | 'right';
}

export const Tooltip: React.FC<TooltipProps> = ({ description, position = 'top' }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const getTooltipPositionClass = () => {
        switch (position) {
            case 'top':
                return 'bottom-full left-1/2 transform -translate-x-1/2 mb-2';
            case 'bottom':
                return 'top-full left-1/2 transform -translate-x-1/2 mt-2';
            case 'left':
                return 'right-full top-0 transform -translate-y-0 mr-2';
            case 'right':
                return 'left-full top-0 transform -translate-y-0 ml-2';
            default:
                return '';
        }
    };

    return (
        <div className="relative">
            <div
                className="cursor-pointer mb-1.5"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                <ToolTipIcon />
            </div>
            {showTooltip && (
                <div className={`w-40 absolute ${getTooltipPositionClass()} bg-white border border-gray-200 p-2 rounded shadow-lg z-10`}>
                    {description}
                </div>
            )}
        </div>
    );
};